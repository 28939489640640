import React from 'react'


export default function Header() {
    return (<>
        <div className="nav-wrapper">
            <div className="mx-auto px-[35px] pt-[3vw] bg-white w-100 mb-[90px] md:mb-[120px] lg:mb-[260px]">
                <div className="grid justify-items-end">
                    <img className="" src={'logo.png'} />
                </div>
            </div>
        </div>
    </>)
}
