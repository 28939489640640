import React,{useState} from 'react'
import ReactDOM from 'react-dom'
import ModalVideo from 'react-modal-video'

export default function VideoSection(data) {
    const [isOpen, setOpen] = useState(false)

    return (<>
    <div className="VideoSection mb-[128px]">
        <div className="video-title mb-[28px] px-[30px]">
            {data.fields?.title &&
                <div dangerouslySetInnerHTML={{__html: data.fields.title}}></div>
            }
        </div>
        {/*autoplay large video*/}
        <div className="video-wrapper pb-[48px]">
            <video width="100%" autoPlay playsInline loop muted>
                <source src="/landingpage_short.mp4" />
            </video>
        </div>
        <div className="flex flex-col sm:flex-row video-sub  p-[90px] bg-[#F3FFFD]">
            <div className="w-full md:w-3/5">
                <div className="subtitle pr-[30px]">
                    {data.fields?.subTitle && 
                        <h3 className="mb-[24px]" dangerouslySetInnerHTML={{__html: data.fields.subTitle}}></h3>
                    }
                    {data.fields?.blurb && 
                        <div className="blurb mb-[70px]">{data.fields.blurb}</div>
                    }
                    <div>
                        {/* <a className="video-play-link" href="https://vimeo.com/704780245/7ec2773791" target="_blank">
                            <img className="inline-block mr-[15px] play-circle w-[48px]" src="play-green.png"/>
                            <img className="inline-block mr-[15px] play-circle-hover w-[48px]" src="play-green-hover.png"/>
                            Watch Video
                        </a> */}
                        <button className="video-play-link" onClick={()=> setOpen(true)}>
                            <img className="inline-block mr-[15px] play-circle w-[48px]" src="play-green.png"/>
                            <img className="inline-block mr-[15px] play-circle-hover w-[48px]" src="play-green-hover.png"/>
                            Watch Video
                        </button>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-2/5 mt-[30px] sm:mt-[0px]">
                {data.fields?.videoThumb && 
                    <button className="w-full" onClick={()=> setOpen(true)}>
                        <img className="w-full" src={data.fields.videoThumb.url} alt="Video Thumbnail"/>
                    </button>
                }
            </div>
        </div>
        <React.Fragment>
            <ModalVideo channel='vimeo' autoplay isOpen={isOpen} videoId="763484978" onClose={() => setOpen(false)} />
        </React.Fragment>
    </div>
        
        
    </>);
}