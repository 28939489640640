import React from 'react';

export default function IconSection(data) {
    return (<>
    <div className="icon-wrapper bg-[#082268] pt-[90px]">
        <div className="sectionHero w-[70%] mb-[80px] mx-auto text-center">
            <div className="form-title mb-[24px]">
                {data.fields?.title && 
                    <h2 dangerouslySetInnerHTML={{__html: data.fields.title}}></h2>
                }
            </div>
            {data.fields?.blurb && 
                <div className="blurb ">
                    <p>{data.fields.blurb}</p>
                </div>
            }
        </div>
        <div className="icon-grid-wrapper">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {data?.fields?.icons.map( icon => (
                    <div key={icon.contentful_id} className="iconCard mx-auto text-center px-[30px] pb-[60px] md:pb-[60px] lg:pb-[90px] xl:pb-[136px]">
                        <img className="mx-auto mb-[32px]" src={icon.image.url} />
                        <h3 className="mb-[34px]">{icon.title}</h3>
                        <p className="px-[60px]">{icon.blurb}</p>
                    </div>
                ))}
            </div>
        </div>
    </div>
    </>)
}