import React, { useState } from 'react';

export default function Footer() {
    const lambdaEndpoint = 'https://scgs6r5bslcqzlzq6rrsy6zpoe0eavya.lambda-url.us-east-1.on.aws/';
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email_address: '',
        career_level: '',
        state: '',
    });

    const handleFormData = (e) => {
        let enteredData = formData;
        enteredData[e.target.name] = e.target.value;
        setFormData(enteredData);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        for (const [key, value] of Object.entries(formData)) {
            if (!value) {
                // Form field required error.
                setShowErrorMessage(true);
                setTimeout(() => setShowErrorMessage(false), 3000);
                return false;
            }
        }

        const response = fetch(lambdaEndpoint, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            if (response.status != 200) {
                throw new Error('Unable to subscribe.');
            }

            setShowSuccessMessage(true);
            setTimeout(() => setShowSuccessMessage(false), 5000);

            response.json();   
        })
        .catch((e) => console.error(e));
    };

    return (<>
    {showSuccessMessage &&
        <div class="fixed left-1 top-0 right-1 p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800 text-center" role="alert">
            <span class="font-medium text-center">
                Thanks! 
                You're all set to receive the latest news and information about Early Intervention!
            </span>
        </div>
    }
    {showErrorMessage &&
        <div class="fixed left-1 top-0 right-1 p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
            <span class="font-medium text-center">Oops, looks like there's some missing fields, please try to register again!</span>
        </div>
    }
    <div className="footer-wrapper bg-[#053F34] text-white p-[30px] lg:px-[60px] lg:py-[30px] xl:px-[130px] xl:py-[72px]">
        <div className="flex flex-col sm:flex-row">
            <div className="w-full md:w-2/3">
                <div className="title md:pb-[126px] pb-[60px]">
                    <span className="dark-green">be part of</span><br/>
                    <span className="green">bright futures</span>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-4 mb-[30px] md:mb-[0px] gap-4 logo-wrapper">
                    <div className="mx-auto"><img className="w-[150px]" src={'logo-white.svg'} alt="placeholder" /></div>
                    <div className="mx-auto"><img className="w-[82px] mt-[-10px]" src={'edu.svg'} alt="placeholder" /></div>
                    <div className="mx-auto"><img className="w-[150px]" src={'ecpc-logo.png'} alt="placeholder" /></div>
                </div>
            </div>
            <div className="w-[100%] md:w-1/3">
                <form name="cc-subscribe-footer" onSubmit={handleFormSubmit}>
                    <input 
                        onChange={handleFormData}
                        tabIndex={0}
                        name="first_name" 
                        type="text" 
                        placeholder="First Name" />
                    <input 
                        onChange={handleFormData}
                        tabIndex={0}
                        name="last_name" 
                        type="text" 
                        placeholder="Last Name" />
                    <input 
                        onChange={handleFormData}
                        tabIndex={0}
                        name="email_address" 
                        type="text" 
                        placeholder="Email Name" />
                    <select
                        className="footer-career"
                        onChange={handleFormData} 
                        tabIndex={0}
                        name="career_level" 
                        placeholder="Career Level">
                        <option value="Career Level">Career Level</option>
                        <option value="High School">High School</option>
                        <option value="Undergraduate">Undergraduate</option>
                        <option value="Graduate">Graduate</option>
                        <option value="Working Professional">Working Professional</option>=
                    </select>
                    <select 
                            name="state" 
                            tabIndex={0}
                            onChange={handleFormData}
                            className="block footer-state appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                                <option value="" disabled selected hidden>Select State</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                        </select>
                    <div className="my-[50px]">
                        <button className="footer-sub" type="submit" tabIndex={0} onClick={() => handleFormSubmit}>
                            <img className="footer-arrow w-[48px] mb-[5px] inline-block mr-[10px]" src="footer-arrow.png"/> 
                            <img className="footer-arrow-hover inline mr-[15px]" src="/footer-cta-arrow-hover.png"/> 
                            Keep Up with EI
                        </button>
                    </div>
                </form>
                <p>We will not sell or use your information for solicitations. Read our privacy policy <a className="footer-hover" href="https://www.dec-sped.org/privacy-policy">here</a>.</p>
            </div>
        </div>
        <div className="container pt-[60px] pb-[60px] max-w-[1000px] mx-auto">
            <p className="text-center">This is a product of the Early Childhood Personnel Center (ECPC) awarded to the University of Connecticut Center for Excellence in Developmental Disabilities and was made possible by Cooperative Agreement #H325B170008 which is funded by the U.S. Department of Education, Office of Special Education Programs. However, those contents do not necessarily represent the policy of the Department of Education, and you should not assume endorsement by the Federal Government.</p>
        </div>
        <div className="grid grid-cols-4 gap-4 social max-w-[800px] m-auto">
            <div>
                <a className="icon" href="https://www.facebook.com/DivisionforEarlyChildhood">
                    <img className="facebook" src={'Facebook.png'} alt="Facebook" />
                    <img className="facebook-green" src={'Facebook-g.png'} alt="Facebook" />
                </a>
            </div>
            <div>
                <a className="icon" href="https://tr.linkedin.com/company/division-for-early-childhood">
                    <img className="linkedin" src={'lir.png'} alt="LinkedIn" />
                    <img className="linkedin-green" src={'lig.png'} alt="LinkedIn" />
                </a>
            </div>
            <div>
                <a className="icon" href="https://www.youtube.com/channel/UCEl1GEkTyh18QKqQSd92Z5w">
                    <img className="youtube" src={'Youtube.png'} alt="YouTube" />
                    <img className="youtube-green" src={'Youtube-g.png'} alt="YouTube" />
                </a>
            </div>
            <div>
                <a className="icon" href="https://twitter.com/decsped?lang=en">
                    <img className="twitter" src={'Twitter.png'} alt="Twitter" />
                    <img className="twitter-green" src={'Twitter-g.png'} alt="Twitter" />
                </a>
            </div>
        </div>
        <div className="container mx-auto">
            <p className="text-center">© 2022 Division for Early Childhood (DEC). All rights Reserved.&nbsp;&nbsp;|&nbsp;&nbsp;<a href="https://www.dec-sped.org/privacy-policy">Privacy Policy</a></p>
        </div>
    </div>

    </>)
}