import React from 'react';

export default function MapPush(data) {
    return (<>
        <section className="MapPush lg:pt-[60px] xl:pt-[90px]">
            <div className="flex flex-row">
                <div className="basis-1/2 map-bg">
                    {data.fields?.image && 
                        <img src={data.fields.image?.url} alt={data.fields.image?.title} />
                    }
                </div>
                <div className="basis-1/2 hero pl-[40px] pr-[120px]">
                    {data.fields?.title && 
                        <div dangerouslySetInnerHTML={{ __html: data.fields.title }}></div>
                    }
                    {data.fields?.blurb && 
                        <p>{data.fields.blurb}</p>
                    }
                    {data.fields?.link &&
                        <a className="map-cta" href={data.fields.link} target="_blank">
                            <img className="map-arrow inline mr-[15px]" src="/green-arrow.png"/> 
                            <img className="map-arrow-hover inline mr-[15px]" src="/map-arrow-hover.png"/> 
                            View Map
                        </a>
                    }
                </div>
            </div>
        </section>
    </>);
}