import * as React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import VideoSection from '../components/VideoSection';
import BoxSection from '../components/BoxSection';
import IconSection from '../components/IconSection';
import ImageCrossSection from '../components/ImageCrossSection';
import MapPush from '../components/MapPush';
import SubscribeForm from '../components/SubscribeForm';

const IndexPage = ({ data }) => {
    return (<>
        {data?.contentfulPage?.components.map(component => {

            if (component.__typename == 'ContentfulMetatags') {
                return (
                    <Helmet key={component.contentful_id}>
                        <title>{component.title}</title>
                        <meta name="description" content={component.description} />
                    </Helmet>
                );
            }

        })}
        <div className="container mx-auto">
            <Header />
                <main>
                    {data?.contentfulPage?.components.map(component => {
                        // console.log(component);
                        switch (component.__typename) {
                            case 'ContentfulBoxSection':
                                return <BoxSection key={component.contentful_id} fields={component} />

                            case 'ContentfulIconSection':
                                return <IconSection key={component.contentful_id} fields={component} />

                            case 'ContentfulImageCrossSection':
                                return <ImageCrossSection key={component.contentful_id} fields={component} />

                            case 'ContentfulMapPush':
                                return <MapPush key={component.contentful_id} fields={component} />

                            case 'ContentfulSubscribeForm':
                                return <SubscribeForm key={component.contentful_id} fields={component} />

                            case 'ContentfulVideoSection':
                                return <VideoSection key={component.contentful_id} fields={component} />
                        }
                    })}
                </main>
            <Footer />
        </div>
    </>)
}

export const query = graphql`
    query FETCH_HOME_PAGE {
        contentfulPage(contentful_id: {eq: "HRjorYp4lvw1eJrVKLiDU"}) {
        title
        components {
            ... on ContentfulVideoSection {
                __typename
                contentful_id
                blurb
                previewVideoUrl
                subTitle
                title
                url
                videoThumb {
                    url
                    title
                }
            }
            ... on ContentfulMetatags {
                __typename
                contentful_id
                title
                description
            }
            ... on ContentfulBoxSection {
                __typename
                contentful_id
                title
                blurb
                cards {
                    contentful_id
                    title
                }
            }
            ... on ContentfulSubscribeForm {
                __typename
                contentful_id
                title
                blurb
            }
            ... on ContentfulIconSection {
                __typename
                contentful_id
                title
                blurb
                icons {
                    contentful_id
                    title
                    blurb
                    image {
                        url
                        title
                    }
                }
            }
            ... on ContentfulImageCrossSection {
                __typename
                contentful_id
                topBgHex
                bottomBackgroundHex
                image {
                    url
                    title
                }
            }
            ... on ContentfulMapPush {
                __typename
                contentful_id
                title
                link
                blurb
                image {
                    url
                    title
                }
            }
        }
    }
}
`;

export default IndexPage
