import React from 'react';

export default function ImageCrossSection(data) {
    const topColor = data.fields.topBgHex;
    const bottomColor = data.fields.bottomBackgroundHex;
    const bgStyle = {
        background: "linear-gradient(180deg,"+ " " + topColor + " " + "50%," + " " + bottomColor + " " + "50%)"
    }
    return (<>
        <div style={bgStyle} className="ImageCross-wrapper py-[30px] px-[15px] md:px-[0] md:py-[48px]">
            <img className="mx-auto" src={data.fields.image.url}/>
        </div>
    </>)
}