import React from 'react';

export default function BoxSection(data) {
    return (<>
        
    <div className="boxSection-wrapper bg-[#FFFAEB] pb-[60px]">
        <div className="sectionHero w-[60%] mb-[80px] mx-auto ">
            <div className="form-title mb-[24px]">
                {data.fields?.title && 
                    <h2 dangerouslySetInnerHTML={{__html: data.fields.title}}></h2>
                }
            </div>
            {data.fields?.blurb && 
                <div className="blurb max-w-[600px]">
                    <p>{data.fields.blurb}</p>
                </div>
            }
        </div>
        <div className="box-wrapper">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {data?.fields?.cards.map( card => (
                    <div key={card.contentful_id} className="card max-w-[288px] mx-auto text-center px-[30px] mb-[30px]">
                        <h3 className="py-[120px] h-100 align-middle">{card.title}</h3>
                    </div>
                ))}
            </div>
        </div>
    </div>
    </>)
}