import React, { useState } from 'react';

export default function SubscribeForm(data) {
    const lambdaEndpoint = 'https://scgs6r5bslcqzlzq6rrsy6zpoe0eavya.lambda-url.us-east-1.on.aws/';
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email_address: '',
        career_level: '',
        state: '',
    });

    const handleFormData = (e) => {
        let enteredData = formData;
        enteredData[e.target.name] = e.target.value;
        setFormData(enteredData);
    };

    const subscribe = async () => {
        for (const [key, value] of Object.entries(formData)) {
            if (!value) {
                // Form field required error.
                setShowErrorMessage(true);
                setTimeout(() => setShowErrorMessage(false), 3000);
                return false;
            }
        }

        const response = fetch(lambdaEndpoint, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            if (response.status != 200) {
                throw new Error('Unable to subscribe.');
            }

            setShowSuccessMessage(true);
            setTimeout(() => setShowSuccessMessage(false), 5000);

            response.json();   
        })
        .catch((e) => console.error(e));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        subscribe();
    };

    return (<>
    {showSuccessMessage &&
        <div class="fixed left-1 top-1 right-1 p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800 text-center" role="alert">
            <span class="font-medium text-center">
                Thanks! 
                You're all set to receive the latest news and information about Early Intervention!
            </span>
        </div>
    }
    {showErrorMessage &&
        <div class="fixed left-1 top-0 right-1 p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
            <span class="font-medium text-center">Oops, looks like there's some missing fields, please try to register again!</span>
        </div>
    }
    <div className="form-section-wrapper mb-[128px]">
        <div className="form-bg">
        <div className="form-section container text-center">
                <div className="sectionHero w-[50%] mx-auto">
                    <div className="form-title mb-[24px]">
                        {data.fields?.title && 
                            <h2 dangerouslySetInnerHTML={{__html: data.fields.title}}></h2>
                        }
                    </div>
                    {data.fields?.blurb && 
                        <div className="blurb mb-[64px]">
                            <p>{data.fields.blurb}</p>
                        </div>
                    }
                </div>
        </div>
        <div className="max-w-[940px] px-[30px] sub-form mx-auto">
            <form name="cc-subscribe" onSubmit={handleFormSubmit}>
                <div className="">
                    <div className="w-full md:w-1/2 inline-block pr-[15px]">
                        <input 
                            className=" focus:outline-none focus:shadow-outline" 
                            name="first_name" 
                            type="text" 
                            tabIndex={0}
                            onChange={handleFormData}
                            placeholder="First Name" />
                    </div>
                    <div className="w-full md:w-1/2 inline-block pr-[15px]">
                            <input 
                                className="focus:outline-none focus:shadow-outline" 
                                name="last_name" 
                                type="text" 
                                tabIndex={0}
                                onChange={handleFormData}
                                placeholder="Last Name" />
                                </div>
                    <div className="w-full md:w-1/2 inline-block pr-[15px]">
                            <input 
                                className=" focus:outline-none focus:shadow-outline" 
                                name="email_address" 
                                type="text"
                                tabIndex={0}
                                onChange={handleFormData}
                                placeholder="Email Address" />
                        </div>
                        <div className="w-full md:w-1/2 inline-block pr-[15px]">
                            <select 
                                name="career_level" 
                                tabIndex={0}
                                onChange={handleFormData}
                                className="block form-career appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                                <option>Career Level</option>
                                <option value="High School">High School</option>
                                <option value="Undergraduate">Undergraduate</option>
                                <option value="Graduate">Graduate</option>
                                <option value="Working Professional">Working Professional</option>
                            </select>
                            </div>
                    {/* <div className="w-full md:w-1/2">
                        <input 
                            className="focus:outline-none focus:shadow-outline" 
                            name="last_name" 
                            type="text" 
                            tabIndex={0}
                            onChange={handleFormData}
                            placeholder="Last Name" />
                        <select 
                            name="career_level" 
                            tabIndex={0}
                            onChange={handleFormData}
                            className="block form-career appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                            <option>Career Level</option>
                            <option value="High School">High School</option>
                            <option value="Undergraduate">Undergraduate</option>
                            <option value="Graduate">Graduate</option>
                            <option value="Working Professional">Working Professional</option>
                        </select>
                    </div> */}
                                        <div className="w-full md:w-1/2 inline-block pr-[15px]">
                        <select 
                            name="state" 
                            tabIndex={0}
                            onChange={handleFormData}
                            className="block form-state appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                                <option value="" disabled selected hidden>Select State</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                        </select>
                    </div>

                        

                </div>
                <div className="w-full md:w-1/2 display-inline mt-[20px] sub" onClick={() => subscribe()} tabIndex={0}> {/*ADAM This should be the submit button */}
                        <img className="inline-block sub-arrow mb-[5px] mr-[15px] w-[48px]" src="Group47.png" />
                        <img className="inline-block mr-[15px] w-[48px] sub-arrow-hover mb-[5px]" src="pink-arrow.png" />
                        <span>Keep Up with EI</span>

                        <div className="sub-info-text mt-[20px]">
                            We will not sell or use your information for solicitations. <br/>
                            Read our privacy policy <a href="https://www.dec-sped.org/privacy-policy">here.</a>
                        </div>
                </div>

            </form>
        </div>
        </div>
    </div>
        
    </>);
}